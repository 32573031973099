import React from 'react'
import './contact.scss'

const Contact = ({ info }) => {

    const contact = info
    return(
        <div className="contact">
            {
                contact.map((item, i) => {

                    const { images, infobox, tags, text, contacttitle, contactinfo, contactlink } = item.childMarkdownRemark.frontmatter
                    console.log(contactlink);
                        
                    return (
                        <div key={i}>
                            <div className="contact__tag">
                                <h2>{contacttitle}</h2>
                            </div>
                            <div className="contact__wrapper">
                                <a href={contactlink} target="_blank" rel="noopener noreferrer">
                                    <div className="contact__wrapper">
                                        <div>{contactinfo}</div>
                                    </div>
                                </a>
                            </div>
                            </div>
                    )
                })
            }
        </div>
    )
}

export default Contact
import React from 'react'
import { Link } from 'gatsby'

// import './navigation.module.scss'
import navigationStyles from './navigation.module.scss'

const Navigation = () => {

    return (
        <>
            <div className={navigationStyles.popUp}>
            <p>+</p>
            </div>
            <nav className={navigationStyles.navigation}>
                <ul className={navigationStyles.navList}>
                    <li>
                        <Link className={navigationStyles.link} activeClassName={navigationStyles.link} to="/">Home</Link>
                    </li>
                    <li>
                        <Link className={navigationStyles.link} activeClassName={navigationStyles.link} to="/contact">Contact</Link>
                    </li>
                    <li>
                        <Link className={navigationStyles.link} activeClassName={navigationStyles.link} to="/about">About</Link>
                    </li>
                </ul>
            </nav>
        </>
    )

} 

export default Navigation
import React from 'react'
// <<<<<<< HEAD
// =======
import { graphql, } from "gatsby"
// This is the real code
// >>>>>>> backup3

import Layout from "../components/layout/layout"
import Contact from "../components/sections/contact"


const ContactPage = ({ data }) => {
    

    const contact = data.allFile.nodes  
    return (
        <Layout>
            <Contact info={contact}/>
        </Layout>
    )} 

export default ContactPage

export const contactQuery = graphql`
  query contactQuery {
    allFile (filter: {relativeDirectory: {eq: "contact"}}) {
      nodes {
            id
            childMarkdownRemark {
                frontmatter {
                    title
                    text
                  
                    infobox {
                        label
                        text
                    }
                    tags {
                        tag
                    }
                    contacttitle
                    contactinfo
                    contactlink
                }
            }
      }
    }
  }
 `
import React from "react"

import Navigation from '../layout/navigation'

import '../layout/variable.scss'
import './intro.scss'

const Glyph = ({ letter }) => {

    const squares = []

    for (let i = 0; i < 6; i++) {
        squares.push("")
    }

    return(
        // showLoading ?
        <div className="glyph">
            {
                squares.map((square, i) => {

                    return(
                        <div key={i} className="glyph__wrapper">
                            <div >
                                <div >
                                    {letter}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


const Intro = ({ visible }) => {

    return (
        <div>
            <div className="intro" style={{ opacity: visible ? 1 : 0}}>
                <div className="page__title">
                    <p>sl Collective</p>
                </div>
                <Glyph letter="s"/>
                <Glyph letter="l" />
                <Glyph letter="c" />
                <Navigation />
            </div>
        </div>
      )
}

export default Intro;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Intro from "../sections/intro"
import { useState, useEffect } from "react"

import Header from "./header"
import "./layout.scss"
import Navigation from '../layout/navigation'


let firstVisit = true;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    const [state, setState] = useState({
      renderIntro: true,
      visibleIntro: true
    })
      
    //  const timerToClearSomewhere = useRef(null) //now you can pass timer to another component

    useEffect( () => {

      const disableScroll = () => {
        if(typeof document !== 'undefined'){
          document.body.style.overflow = 'hidden'
        }
      }
      if(firstVisit) disableScroll()

      const enableScroll = () => {
        if(typeof document !== 'undefined'){
          document.body.style.overflow = 'unset'
        }
      }

      const fadeIntro = () => {
        setState({
          ...state, 
          visibleIntro: false
        })
      }
      const removeIntro = () => {
        firstVisit = false
        enableScroll()
        setState({
          ...state, 
          renderIntro: false
        })
      }

      if(firstVisit){
        setTimeout(fadeIntro, 5000)
        setTimeout(removeIntro, 5600)

      }

      const cleanUp = () =>{
        clearTimeout(removeIntro)
        clearTimeout(fadeIntro)
      }

      return cleanUp
    })



    return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div className="container">
        <div className="background">
          <div /><div /><div /><div /> <div /> <div />         
        </div>
        <div className="content">
          <main>{children}</main>
        </div>
      </div>
      <Navigation />
      { firstVisit && state.renderIntro && <Intro visible={state.visibleIntro}/>}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
